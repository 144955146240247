@charset "utf-8";
/* 로딩 시작 */
.wrap-loading {
  /*화면 전체를 어둡게 합니다.*/
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65); /*not in ie */
  opacity: 0.9;
  z-index: 9999;

  &.control {
    background: rgba(0, 0, 0, 0.65);
  }
}
.wrap-loading .loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -75px;
}

.display-none {
  display: none;
}

.spinner__container {
  display: flex;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  padding: 0px;
  /*    border: 1px solid #fff;*/
}

.wrap-loading .loading-txt {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: block;
  width: 100%;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;

  &.black {
    color: black;
  }
}
.loading-container .spinner__dot {
  width: 15px;
  height: 15px;
  margin: 0 10px;
  border-radius: 100%;
}
.loading-container .spinner__square {
  width: 10px;
  height: 10px;
}
@keyframes spinner__effect--8-child-1 {
  50% {
    transform: translate(-325%, -325%);
    background-color: #9d4bc7;
  }
}
@keyframes spinner__effect--8-child-2 {
  50% {
    transform: translate(0, -325%);
    background-color: #9d4bc7;
  }
}
@keyframes spinner__effect--8-child-3 {
  50% {
    transform: translate(325%, -325%);
    background-color: #ffc61d;
  }
}
@keyframes spinner__effect--8-child-4 {
  50% {
    transform: translate(325%, 0);
    background-color: #ffc61d;
  }
}
@keyframes spinner__effect--8-child-5 {
  50% {
    transform: translate(325%, 325%);
    background-color: #29e2ff;
  }
}
@keyframes spinner__effect--8-child-6 {
  50% {
    transform: translate(0, 325%);
    background-color: #29e2ff;
  }
}
@keyframes spinner__effect--8-child-7 {
  50% {
    transform: translate(-325%, 325%);
    background-color: #ff2c6e;
  }
}
@keyframes spinner__effect--8-child-8 {
  50% {
    transform: translate(-325%, 0);
    background-color: #ff2c6e;
  }
}
@keyframes spinner__effect--8-child-9 {
  50% {
    transform: scale(2);
  }
}
.loading-container .spinner__effect--8 .spinner {
  position: relative;
  transform: rotate(45deg);
}
.loading-container .spinner__effect--8 .spinner .spinner__square {
  position: absolute;
  background: #fff;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(1) {
  animation-name: spinner__effect--8-child-1;
  animation-delay: calc(0.1s * 1);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(2) {
  animation-name: spinner__effect--8-child-2;
  animation-delay: calc(0.1s * 2);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(3) {
  animation-name: spinner__effect--8-child-3;
  animation-delay: calc(0.1s * 3);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(4) {
  animation-name: spinner__effect--8-child-4;
  animation-delay: calc(0.1s * 4);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(5) {
  animation-name: spinner__effect--8-child-5;
  animation-delay: calc(0.1s * 5);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(6) {
  animation-name: spinner__effect--8-child-6;
  animation-delay: calc(0.1s * 6);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(7) {
  animation-name: spinner__effect--8-child-7;
  animation-delay: calc(0.1s * 7);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(8) {
  animation-name: spinner__effect--8-child-8;
  animation-delay: calc(0.1s * 8);
}
.loading-container .spinner__effect--8 .spinner .spinner__square:nth-child(9) {
  animation-name: spinner__effect--8-child-9;
  animation-delay: 1.25s;
}

/* 로딩 끝 */
