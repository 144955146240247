@charset "utf-8";

#combineMenu {
  background: #000000;
  position: fixed;
  // width: 100%;
  top: 0;
  height: 60px;
  z-index: 9999;
  line-height: 1;
  width: 100vw;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  .cbm-sel-lang {
    position: absolute;
    top: 18px;
    text-align: center;
    right: 0;
    left: 0;
    margin: 0 auto;
    // width: 60%;
    z-index: 1;

    .box {
      background: #333;
      top: 40px;
    }
  }
  .btn {
    width: 100%;
    font-weight: 700;
    border-radius: 0.625rem;
    line-height: 1;
    padding: 0.75rem 0;
    box-sizing: border-box;

    &.purple {
      background: #713fff;
    }

    &.blue {
      background: var(--primary-200);
    }

    &.gray {
      background: #323232;
    }
  }

  .box {
    border-radius: 8px;
    background: #222;
    padding: 1.875rem 1.25rem;

    &.drop {
      position: absolute;
      top: 4.375rem;
      width: 360px;
      box-sizing: border-box;
      cursor: auto;

      &::before {
        display: inline-block;
        content: "";
        position: absolute;
        top: -1rem;
        right: 0;
        width: 0;
        height: 0;
        border-top: 1.25rem solid transparent;
        border-bottom: 1.25rem solid transparent;
        border-left: 1.25rem solid transparent;
        border-right: 1.875rem solid #222;
        border-radius: 0.625rem 0.625rem 0 0;
      }
    }
  }

  .user-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .nickname {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
  }

  .gray-box {
    border-radius: 8px;
    background: #323232;
    padding: 0.625rem;
  }

  .logo-area {
    text-align: right;
    display: inline-block;
    margin-top: 18px;
    padding-left: calc(50% - 78px);

    img {
      height: 24px;
    }

    a {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 10px;
      font-weight: bold;
      color: var(--gray-900);
      padding: 1px 6px;
      border-radius: 8px;
      background: var(--primary-200);
      line-height: 100%;
      margin-left: 5px;

      padding: 3px 6px;
    }
  }

  .select {
    border-radius: 4px;
    border: 1px solid #777;
    color: #777;
    // position: relative;
    margin-bottom: 1.875rem;

    button {
      background: transparent;
      color: #777;
      padding: 0.625rem 0.75rem;
      width: 100%;
      box-sizing: border-box;

      &:hover {
        color: var(--gray-100);
      }

      &.row-flex {
        margin-bottom: 0;
        color: var(--gray-100);
      }
    }

    ul {
      background: #222;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
    }

    li {
      padding: 0.625rem 0.75rem;
      cursor: pointer;
      border-top: 1px solid #777;
      font-size: 12px;

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        color: var(--gray-100);
      }

      &.row-flex {
        margin-bottom: 0;
      }

      &.plus-account {
        padding: 0.75rem 0.875rem;

        svg {
          margin-left: 0.625rem;
        }
      }

      &.on {
        color: var(--gray-100);
      }
    }
  }

  .right-area {
    position: absolute;
    right: 2.5rem;
    height: 60px;
    top: 0;
    color: var(--gray-100);
    font-size: 14px;
    display: flex !important;
    align-items: center;

    .tab {
      margin-right: 1.25rem;
      cursor: pointer;

      display: inline-flex;
      align-items: center;
      height: 100%;

      &:last-child {
        margin-right: 0;
      }

      .dot-area {
        position: relative;
        height: inherit;

        span {
          position: absolute;
          width: 6px;
          height: 6px;
          z-index: 1;
          border-radius: 50%;
          background: #c12ff4;
          right: -24px;
          top: 1rem;
        }
      }
    }

    .fa-bell {
      font-size: 20px;
    }

    .launcher-btn {
      svg {
        font-size: 16px;
        margin-left: 0.625rem;
      }
    }

    .cbm-sel-lang {
      position: static;

      .box {
        top: 60px;
        text-align: left;
        background: #222;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .right-area {
      .launcher-btn,
      .lang {
        display: none;
      }
    }

    .logo-area {
      padding-left: calc(50% - 68px);
    }

    .cbm-sel-lang {
      width: 60%;
    }
  }

  @media screen and (max-width: 450px) {
    .box.drop {
      width: 100dvw;
      top: 60px;
      right: -2.5rem;

      &::before {
        content: none;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .logo-area {
      padding-left: 3rem;

      span {
        display: none;
      }
    }
    .right-area {
      right: 1.25rem;
    }
    .box.drop {
      right: -1.25rem;
    }
  }
}
