@charset "utf-8";

#loginForm {
  * {
    font-size: 0.875rem;
  }

  .mt20 {
    margin-top: 20px;
  }

  .input-area {
    // width: 20.125rem;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #bbb;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:last-of-type {
      margin-top: 10px;
    }

    svg {
      margin-right: 10px;
      color: #bbbbbb;
    }
  }

  input[type="text"],
  input[type="password"] {
    padding: 10px;
    border: none;
    height: auto;
    min-width: auto !important;
    margin: 0 !important;
    color: #8e9297;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #8e9297 !important;
  }

  .form-title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #111;

    svg {
      position: absolute;
      left: 0;
      font-size: 18px;
      cursor: pointer;
    }
  }

  p {
    color: #555555;

    &.fs12 {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 400px) {
    .input-area {
      width: 100%;
    }
  }
}
