@charset "utf-8";

.login-right {
  font-size: 14px;
  width: 322px;
  padding-left: 3.75rem;
  box-sizing: content-box;

  .links {
    margin-top: 20px;
    text-align: center;

    a {
      color: #555;
      line-height: 1;
      margin-right: 20px;
      font-size: 14px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  button:disabled,
  button[disabled] {
    background: #bbbbbb !important;
  }

  .btn {
    border-radius: 4px;
    color: #fff;
    margin-top: 1.5rem;
    width: 100%;
    font-weight: 700;
    line-height: 1;
    padding: 0.75rem 0;
    border: none;
    height: auto;
    line-height: 1.5;
    height: 39px !important;
    box-sizing: border-box;
    line-height: 1;

    &.purple {
      background: #713fff;

      &:hover {
        background: #7e58e9;
        border: none;
      }
    }
  }

  .signup {
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 24px !important;
    overflow: hidden;
    position: relative;
    color: #111 !important;
    width: 100%;
    display: inline-block;

    background: linear-gradient(
        101deg,
        #ee376b 0%,
        #ca3995 33%,
        #9b3ccd 66%,
        #713fff 100%
      )
      0% 0% / 300% 100% !important;

    animation: anime-grad 3s infinite ease-in-out forwards;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 2px;
      left: 2px;
      border-radius: 2px;
      font-size: 14px;

      position: absolute;
      background: #fff;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
    }

    span {
      margin-left: 4px;
      color: #713fff;
      font-size: 14px;
    }
  }

  .sns-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    li {
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      color: #111;

      > div {
        width: 24px;
        height: 24px;
        background: #fff;
        margin-right: 8px;
        border-radius: 16.528px;

        display: flex;
        justify-content: center;
        align-items: center;

        i {
          display: inline-block;
          width: 12px;
          height: 12px;
          background-size: contain !important;
        }

        i.fb {
          width: inherit;
          height: inherit;
        }
      }

      &:last-child {
        padding-right: 10px;
      }
    }

    .gg {
      background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-gg.png)
        no-repeat;
    }
    .fb {
      background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-fb.png)
        no-repeat;
    }
    .ap {
      background: url(//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/icon-apple.png)
        no-repeat;
    }
  }

  @media screen and (max-width: 800px) {
    padding-left: 0;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
}

@keyframes anime-grad {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
