@charset "utf-8";

#vfun-list-toggle {
  position: absolute;
  top: 19px;
  right: 18px;

  button {
    background: inherit;
    padding: 0;
  }
}
