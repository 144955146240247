@charset "utf-8";

.cbm-sel-lang {
  .box {
    padding: 10px 20px !important;
  }

  .sel-lang {
    color: var(--gray-100);
    vertical-align: super;
    display: inline-flex;
    align-items: center;
    height: 25px;
    background: transparent !important;
    padding: 0;

    font-size: 14px;
    font-weight: normal;

    img {
      margin-left: 4px;
    }

    &:hover {
      background: transparent !important;
    }
  }

  ul {
    position: absolute;
    width: 156px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-sizing: border-box;
  }

  li {
    cursor: pointer;

    button {
      display: inline-block;
      color: rgba(255, 255, 255, 0.3);
      padding: 10px 0;
      width: 100%;
      background: transparent !important;

      font-size: 14px;
      font-weight: normal;

      &.on,
      &:hover {
        color: inherit;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
