@charset "UTF-8";
:root {
  --blue: #4271f5;

  // --gray-900: #121212;
  // --gray-800: #1a1b20;
  // --gray-700: #212124;
  // --gray-600: #2b2d34;
  // --gray-500: #3c3e47;
  // --gray-400: #5d5e65;
  // --gray-300: #828282;
  // --gray-200: #cdcdcd;
  // --gray-100: #fff;

  --gray-900: #111;
  --gray-800: #222;
  --gray-700: #333;
  --gray-600: #444;
  --gray-500: #555;
  --gray-400: #777;
  --gray-300: #999;
  --gray-200: #bbb;
  --gray-100: #ddd;

  --primary-300: #5bcae6;
  --primary-200: #14e5ff;
  --primary-100: #00abc2;

  --bg-default: var(--gray-900);
  --basics-color: var(--gray-800);
  --point-color: var(--primary-200);
}
