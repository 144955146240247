@charset "utf-8";

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 300;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.eot);
  src: local("M PLUS 1p Light"), local("MPLUS1p-Light"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-300.svg#MPLUS1p)
      format("svg");
}

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 400;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.eot);
  src: local("M PLUS 1p"), local("MPLUS1p-Regular"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-regular.svg#MPLUS1p)
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "M PLUS 1p";
  font-style: normal;
  font-weight: 700;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.eot);
  src: local("M PLUS 1p Bold"), local("MPLUS1p-Bold"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/mplus1p/m-plus-1p-v19-japanese-700.svg#MPLUS1p)
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cinzel";
  font-weight: 400;
  src: local("☺"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/Cinzel/Cinzel-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Cinzel";
  font-weight: bold;
  src: local("☺"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/Cinzel/Cinzel-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Noto Serif KR";
  font-style: normal;
  font-weight: 400;
  src: url(https://file.qijisoft.com/Valofe_file/fonts/noto-serif-kr-v6-latin_korean-regular.eot);
  src: local("Noto Serif KR"), local("NotoSerifKR-Regular"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-regular.svg#NotoSerifKR)
      format("svg");
}

@font-face {
  font-family: "Noto Serif KR";
  font-style: normal;
  font-weight: 900;
  src: url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.eot);
  src: local("Noto Serif KR Black"), local("NotoSerifKR-Black"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.woff2)
      format("woff2"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.woff)
      format("woff"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.ttf)
      format("truetype"),
    url(https://file.qijisoft.com/Valofe_file/web/fonts/noto-serif-kr-v6-latin_korean/noto-serif-kr-v6-latin_korean-900.svg#NotoSerifKR)
      format("svg");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 100;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-light.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 400;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-regular.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 500;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-medium.woff")
      format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-style: normal;
  font-weight: 700;
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.eot");
  src: url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.woff2")
      format("woff2"),
    url("https://file.qijisoft.com/Valofe_file/web/fonts/notokr-bold.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 400;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Regular.otf)
    format("opentype");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 500;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Medium.otf)
    format("opentype");
}

@font-face {
  font-family: "Noto Sans SC";
  font-style: normal;
  font-weight: 700;
  src: url(//file.qijisoft.com/Valofe_file/web/fonts/NotoSansSc/NotoSansSC-Bold.otf)
    format("opentype");
}

* {
  font-family: "NotoKr", "Helvetica Neue", "Noto Sans SC", "Malgun Gothic",
    Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", "M PLUS 1p", sans-serif;
  word-break: normal;
  -webkit-font-smoothing: antialiased;
}

:lang(jp) {
  font-family: "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Hiragino Sans CNS",
    "Yu Gothic", "M Plus 1p", sans-serif;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
}

:lang(cn) {
  font-family: "Noto Sans SC", "Arial", "M PLUS 1p", "Helvetica Neue",
    "Malgun Gothic", Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", sans-serif;
}

#combineMenu * {
  font-family: "NotoKr", "Helvetica Neue", "Noto Sans SC", "Malgun Gothic",
    Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", "M PLUS 1p", sans-serif !important;
  word-break: normal !important;
  -webkit-font-smoothing: antialiased !important;
}

:lang(cn) #combineMenu *,
:lang(jp) #combineMenu * {
  font-family: "Arial", "M PLUS 1p", "Noto Sans SC", "Helvetica Neue",
    "Malgun Gothic", Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", sans-serif !important;
  word-break: break-all !important;
  -webkit-font-smoothing: antialiased;
}

:lang(cn) #combineMenu * {
  font-family: "Noto Sans SC", "Arial", "M PLUS 1p", "Helvetica Neue",
    "Malgun Gothic", Apple SD Gothic Neo, "애플 SD 산돌고딕 Neo", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: 0 0;
  font-style: normal;
}
h1 {
  font-style: normal;
  font-weight: 700;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
li,
nav ul {
  list-style: none;
}
img {
  object-fit: cover;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
hr {
  display: block;
  height: 2px;
  border: 0;
  margin: 0;
  padding: 0;
}
body,
html {
  font-size: 16px;
  font-size: 1rem;
  // scrollbar-gutter: stable;
}
html {
  overscroll-behavior-y: none;
  scrollbar-gutter: stable;
}
body {
  background-color: var(--bg-default);

  // overscroll-behavior-y: none;
}
body::-webkit-scrollbar {
  width: 8px;
  background: var(--gray-600);
}
body::-webkit-scrollbar-button {
  height: 15px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #666;
  box-shadow: inset 1px 1px 1px rgb(0 0 0 / 50%),
    inset -2px -2px 2px rgb(0 0 0 / 12%);
}
body::-webkit-scrollbar-track {
  background-color: transparent;
  height: 100%;
  filter: brightness(80);
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  box-sizing: border-box;
  outline: none;
  color: #8e9297;
  background-color: #fff;
  cursor: default;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder,
::-webkit-input-placeholder,
::placeholder,
textarea:-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-moz-placeholder,
textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: #6e788e !important;
  text-align: left;
  font-size: 14px;
}

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  width: 100%;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}

// 여기부터
a {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  background: 0 0;
  text-decoration: none;
}
img {
  object-fit: cover;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
body,
html {
  font-size: 16px;
}
html {
  overscroll-behavior-y: none;
  scrollbar-gutter: stable;
}
body {
  background-color: var(--bg-default);
}
a {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
button {
  display: inline-block;
  box-sizing: border-box;
  border: 0;
  font-style: normal;
  text-align: center;
  box-shadow: none;
  text-shadow: none;
  background-image: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.shadow {
  /*display:none; */
  z-index: -1 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  padding-bottom: 0px;
  top: 0;
  left: 0;
  background-size: cover;
  background: rgba(0, 0, 0, 0.75) !important;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
}
.y-scroll {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(#5d5e65, 0.55);
    // border: 1px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#5d5e65, 0.95);
  }
}
.x-scroll {
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(#5d5e65, 0.55);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#5d5e65, 0.95);
  }
}
.row-w {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
}
span {
  font-size: inherit;
}
#valofe-footer {
  padding-bottom: 25px;
  position: relative;
  width: 100%;
  // bottom: 0;
  background: #000;
  z-index: 99;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .line {
    width: 100%;
    height: 1px;
    margin: 32px auto;
    background-color: #4c4c4d;
  }

  .copyright {
    line-height: 1;
    color: #fff;

    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;
      display: inline-block;
      width: 94px;
      height: 30px;
      background: url(https://file.qijisoft.com/Valofe_file/web/vfun/images/logo/logo-valofe-one-white-txt.png)
        0 center no-repeat;
      background-size: 100%;
    }

    p {
      display: inline-block;
      margin-left: 28px;
    }

    .entity-c {
      vertical-align: baseline;
      font-family: "Arial", sans-serif;
      display: inline-block;
    }

    .year-of-copyrights {
      display: inline-block;
      vertical-align: baseline;
      margin-left: 8px;
      margin-right: 4px;
    }
  }
  .nav {
    width: auto;
    margin-right: 8px;

    ul {
      li {
        float: left;
        margin-left: 18px;
        font-size: 12px !important;

        &:first-child {
          margin-left: 32px;
        }
      }

      a {
        color: #fff;
      }
    }
  }

  .footer-logo {
    float: left;
    margin-top: 0;
  }

  .grade {
    color: #e6e6e6;
    text-align: center;

    img {
      display: inline-block;
      height: 60px;
    }

    p {
      display: inline-block;
      font-size: 12px;
      margin-left: 5px;
    }
    table {
      background: rgba(253, 185, 52, 0.8);
      width: 418px;
      max-width: 458px;
      // height: 70px;
      box-sizing: border-box;
      text-align: left;
      table-layout: initial;
      display: inline-block;
      margin-left: 10px;

      tr,
      td {
        color: #fff;
        font-size: 11px;
        padding: 0 10px;
        text-align: start;
      }

      td {
        border-right: 1px solid #f7f7f7;
        &:nth-last-child(1) {
          border-right: none;
        }
      }

      tr {
        border-bottom: 1px solid #f7f7f7;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  html,
  body {
    font-size: 13px;
  }

  .row-w {
    width: calc(100% - 32px) !important;
    max-width: 1100px !important;

    -ms-overflow-style: none; /* IE and Edge : 삭제하면 안됩니다. 세로 스크롤 더 생김 */
    scrollbar-width: none; /* Firefox : 삭제하면 안됩니다. 세로 스크롤 더 생김  */
  }
  .row-w::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera  : 삭제하면 안됩니다. 세로 스크롤 더 생김 */
  }
}

@media screen and (max-width: 1024px) {
  #valofe-footer {
    .row-w:nth-of-type(3) {
      display: block;
      text-align: center;
    }
    .copyright,
    .footer-logo,
    .copyright p,
    .nav {
      display: block;
      width: 100%;
      float: none;
      text-align: center;
      clear: both;
      margin: 0;
    }

    .flex {
      flex-direction: column;
    }

    .copyright {
      .logo {
        float: none;
      }

      p {
        margin-top: 8px;
      }
    }

    .nav {
      margin: 10px auto 0;
      display: block;
      width: 100%;
      float: none;
      text-align: center;

      ul {
        display: inline-block;

        li:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  #valofe-footer {
    .nav ul li {
      margin-left: 10px;
      font-size: 0.5rem !important;

      &:first-child {
        margin-left: 10px;
      }
    }

    .copyright p {
      margin-left: 0;
    }
  }
}
