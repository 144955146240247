@charset "utf-8";

.notification {
  &.drop {
    right: 3.75rem;
  }

  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    ul {
      font-size: 12px;
      li {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      svg {
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }

  .services-tab {
    position: relative;
    font-weight: 500;
    color: var(--gray-500);
    margin-bottom: 20px;

    ul {
      border-bottom: 1px solid var(--gray-500);
      display: flex;
      justify-content: space-between;
    }

    li {
      padding: 0 20px 10px;
      cursor: pointer;

      width: calc(100% / 3);
      text-align: center;

      &.on {
        color: var(--gray-100);
        transition: color 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      }

      &.off {
        color: var(--gray-500);
        transition: color 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        display: inline-block;
      }
    }
  }
  .dot {
    position: absolute;
    width: 6px;
    height: 6px;
    z-index: 1;
    border-radius: 50%;
    background: #c12ff4;
    margin-left: 0.5rem;
  }

  .under-bar {
    position: absolute;
    border-bottom: 1px solid #c12ff4;
    top: 0;
    bottom: 0;
    transition: left 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  }

  .notice-list {
    max-height: 380px;
    overflow-y: scroll;
    padding-right: 2px;
    padding-left: 4px;

    li {
      margin-bottom: 10px;
      // display: flex;

      &.gray-box:hover {
        background: var(--gray-500) !important;
        transition: background 0.4s ease-in-out;
      }

      .user-img {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50%;

        p {
          margin: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: var(--gray-100);
        width: 100%;

        &.visit .cont {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      p {
        color: rgba(255, 255, 255, 0.3);
        font-size: 12px;
        margin-bottom: 5px;
        line-height: 1;
        display: inline-block;
      }

      .cont {
        line-height: 1.5;
        color: var(--gray-100);
        font-size: 14px;
        margin-bottom: 0;
        display: inline-block;

        strong {
          display: inline-block;
          line-height: 1;
          vertical-align: baseline;
        }
      }

      &.loading {
        text-align: center;
        width: 100%;
        display: inline-block;
        padding: 0.5rem 0;

        svg {
          margin-right: 6px;
        }
      }
    }

    .type_1 {
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div p {
          margin-left: 10px;
        }

        img {
          display: inline-block;
        }
      }
      .cont {
        margin-left: 30px;
      }
    }

    .type_2 {
      a {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > div:first-of-type {
          width: 200px;
          margin-right: 10px;
          display: flex;
          flex: 1;
        }
      }

      .cont {
        margin-top: -0.25em;
        margin-left: 10px;
        width: calc(100% - 30px);
      }
    }
  }

  .none {
    text-align: center;
    width: 100%;
    display: inline-block;
    padding: 0.5rem 0;
  }

  @media screen and (max-width: 1200px) {
    &.drop {
      right: 4.5rem;
    }
  }
}
