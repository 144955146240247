@charset "utf-8";

#qrArea {
  padding-right: 3.75rem;
  border-right: 2px solid #d9d9d9;
  width: 268px;
  box-sizing: content-box;

  .title {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    color: #111;

    .gray-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      background: #bbb;
      width: 22px;
      height: 22px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;

      svg {
        color: #fff;
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }

  .how-to-wrap,
  .qr-cont {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .qr-wrap {
    position: relative;
    height: 308px;
  }

  .qr-cont {
    opacity: 1;
  }

  .how-to-wrap {
    opacity: 0;
  }

  .qr-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .qr {
    width: 168px;
    height: 168px;
    background: #bbb;
    margin: 20px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;

    img {
      width: 100%;
      height: 100%;

      &.blur {
        filter: blur(0.5rem);
      }
    }

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 1.5rem;
      cursor: pointer;
      color: #fff;
    }
  }

  .how-to-wrap {
    li {
      padding: 0;
    }

    img {
      margin: 54px 0;
      height: 134px;
    }
  }

  p,
  li {
    font-size: 14px;
    color: #555555;
    font-weight: 500;
    padding: 0 16px;
    line-height: 1.5 !important;
  }

  p {
    text-align: center;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .gray.btn {
    border-radius: 4px;
    background: #bbb;
    color: #fff;
    display: inline-block;
    padding: 10px;
    width: auto;
    height: auto;
    line-height: 1;
    margin-right: 10px;
    font-size: 14px !important;
    font-weight: 500;
    border: none;
    text-transform: none;

    &:last-of-type {
      margin: 0;
    }

    svg {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
}
