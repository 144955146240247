@charset "utf-8";

#profile {
  &.drop {
    right: 1rem;
  }

  .title {
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .nickname {
    max-width: 184px;
    color: var(--gray-100);
    line-height: 1.3;
  }

  .row-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      &.purple {
        width: 66px;
        border-radius: 50px;
        text-align: center;
        box-sizing: border-box;
      }
    }

    img {
      cursor: pointer;
      // width: auto;
    }
  }

  .gray-box.row-flex {
    margin-bottom: 10px;
  }

  .info-wrap {
    max-height: 467px;
    overflow-y: scroll;
    padding-right: 2px;
    padding-left: 4px;

    // 임시
    margin-top: 20px;

    > li {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      &.gray-box {
        padding: 10px 20px !important;
      }

      li {
        & {
          padding: 10px 0;
          border-bottom: 1px solid #444;
        }

        button {
          background: transparent;
          color: #bbb;
          padding-left: 0;
          padding: 8px 0;
          font-size: 14px;

          svg {
            margin-right: 10px;
          }
        }

        a {
          color: #bbb;
          // background: red;
          padding: 10px 0;
          width: 100%;

          svg {
            margin-right: 10px;
          }
        }

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

    .sub-title {
      padding-bottom: 0.5rem;
    }
  }

  .gray-box {
    p,
    a {
      display: inline-block;
    }

    .purple {
      padding: 4px 9px;
      background: #713fff;
      color: var(--gray-100);
      border-radius: 50px;
      margin-left: 20px;
      min-width: auto;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    text-align: start;
    padding: 10px;

    // 리셋 설정
    border: none;
    height: auto;
    width: 100%;
    margin: 0;
    line-height: 1.2;
    text-transform: none;

    svg {
      margin-right: 10px;
    }

    &.purple:hover {
      background: #a585ff;
    }

    &.gray:hover {
      background: var(--gray-500);
    }

    &::before {
      display: none;
    }
  }

  .sign-in li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-height: 800px) {
    .info-wrap {
      max-height: 260px;
    }
  }
}
