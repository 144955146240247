@charset "utf-8";

.bm-burger-button,
.bm-cross-button {
  position: fixed !important;
  width: 16px !important;
  height: 16px !important;
  left: 2.5rem !important;
  top: 22px !important;
}

.bm-cross {
  height: 16px !important;
  top: inherit;
  right: inherit;
}

#sideMenu {
  background: #222;
  width: 360px !important;

  .bm-menu {
    background: #222;
    height: calc(100% - 60px) !important;
    margin-top: 59px;
    border-top: 1px solid #333;
    color: var(--gray-100);
    font-size: 12px;
  }

  .bm-item-list {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .bm-item {
      padding: 1.875rem 2.5rem;

      .pb20 {
        padding-bottom: 1.25rem;
      }

      li {
        margin-bottom: 0.5rem;

        a,
        div {
          color: #777;
          width: 100%;
          display: inline-block;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          a,
          div {
            color: var(--gray-100);
          }
        }
      }

      .logo {
        margin-bottom: 1rem;
        display: inline-block;
      }
    }
  }

  .title,
  .sub-title {
    font-weight: bold;
  }

  .title {
    font-size: 20px;
  }

  .sub-title {
    font-size: 16px;
    margin-bottom: 0.625rem;
  }

  .short-cut {
    background: #222;

    .title {
      margin-bottom: 1.875rem;
    }

    ul {
      display: flex;
      align-items: flex-start;
    }

    li {
      margin-right: 1.875rem;
      text-align: center;
      box-sizing: border-box;
      margin-bottom: 0 !important;

      a {
        color: var(--gray-100) !important;

        &:hover {
          transform: scale(1.1);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 36px;
      height: 36px;
      margin-bottom: 0.625rem;
      object-fit: contain;
    }
  }

  #cont-wrap,
  .all-game-list {
    overflow-y: scroll;
    line-height: 1.3;
    flex: 1;

    &.bm-item {
      background: #181818;
    }

    .title {
      margin-bottom: 1.25rem;
      position: relative;
    }

    .sub-title {
      margin-top: 0.625rem;
      color: var(--gray-100);
    }

    a.sub-title {
      margin: 0.625rem 0;
      display: block;
    }

    &.bm-item {
      background: #181818;
    }
  }

  .all-game-list {
    &.bm-item {
      background: #181818;
    }

    a {
      display: flex;
      align-items: center;

      span {
        letter-spacing: -0.05px;
        font-size: 10px;
        text-align: center;
        padding: 0 0.5rem;
        border-radius: 20px;
        margin-left: 10px;
        color: var(--gray-100);
        line-height: 1;
        background: linear-gradient(
            101deg,
            #ee376b 0%,
            #ca3995 33%,
            #9b3ccd 66%,
            #713fff 100%
          )
          0% 0% / 300% 100%;
        animation: anime-grad 6s ease-in-out infinite;
      }
    }

    .input-area {
      position: absolute;
      right: 0;
      top: -3px;
      background: var(--gray-700);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);

      input {
        background: var(--gray-700);
        padding: 0.375rem;
        width: calc(100% - 1.5rem);
        outline: none;
        border: none;
        box-sizing: border-box;

        // 리셋
        min-width: auto;
        height: 30px;
        margin: 0;
        font-style: normal;
      }
    }

    .fa-magnifying-glass {
      font-size: 16px;
      cursor: pointer;
      color: var(--gray-400);
      padding: 0.5rem;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 450px) {
    width: 100% !important;
  }

  @media screen and (max-height: 750px) {
    .bm-item-list .bm-item {
      padding: 1rem 2.5rem;
    }

    .short-cut .title {
      display: none;
    }
  }
}
@media screen and (max-width: 360px) {
  .bm-burger-button {
    left: 1.25rem !important;
  }
}
@keyframes anime-grad {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
